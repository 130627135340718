import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useEffect, useMemo } from "react";
import { ErrorRetry } from "./LoaderLayout";
import { PoweredByMixinRoute } from "../page/PoweredByMixinRoute";

export const ErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  const isFetchDynamicallyError = useMemo(() => {
    if (!error) return;
    if (typeof error !== "object") return;
    if (!("message" in error)) return;
    return (
      `${error.message}`.includes(
        "Failed to fetch dynamically imported module",
      ) || `${error.message}`.includes("is not a valid JavaScript MIME type")
    );
  }, [error]);

  useEffect(() => {
    if (!isFetchDynamicallyError) return;
    window.location.reload();
  }, [error, isFetchDynamicallyError]);

  if (isFetchDynamicallyError) return <></>;

  return (
    <div className="flex h-full flex-col">
      <ErrorRetry retry={() => window.location.reload()} err={error} />
      <PoweredByMixinRoute />
    </div>
  );
};
