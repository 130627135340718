import z from "zod";
import { zodObjectOrUndefined } from "./utils";

export interface Chan {
  chan_id: string;
  name: string;
  icon_url: string;
}

export interface Asset {
  asset_id: string;
  icon_url: string;
  name: string;
  symbol: string;
  chain: Chan;
}

export interface Authorization {
  authentication_token: string;
  user_id: string;
}

export interface Profile {
  kyc_state: "initial" | "pending" | "success" | "retry" | "blocked" | "ignore";
  identity_number: string;
  user_id: string;
  is_messenger_user: boolean;
}

export interface Config {
  assets: Asset[];
  currencies: {
    currency: string;
    precision: number;
  }[];
  support_payments: string[];
}

export interface RouteQuoteRequest {
  amount?: string;
  currency: string;
  asset_id: string;
  destination?: string;
}

export interface RouteQuote {
  currency: string;
  total_amount: string;
  purchase: string;
  fee_by_gateway: string;
  fee_by_mixin: string;
  asset_price: string;
  asset_amount: string;
  fee_percent: string;
  minimum: string;
  maximum: string;
  fee_by_network?: string;
}

export interface RouteCard {
  last4: string;
  scheme: string;
  instrument_id: string;
  card_type: string;
  user_id: string;
}

export interface RouteInstrumentRequest {
  token: string;
  name: string;
  phone: string;
}

export interface CreateOrderRequest {
  completion_type: "hosted"; // Only support hosted with card
  asset_id: string;
  // For apple pay or google pay
  token?: never;
  instrument_id: string;
  scheme: string;
  amount: string;
  asset_amount: string;
  currency: string;
  destination?: string;
}

export interface Order {
  order_id: string;
  amount: string;
  currency: string;
  asset_amount: string;
  fee_by_gateway: string;
  fee_by_mixin: string;
  price: string;
  asset: Asset;
  state: "initial" | "created" | "paying" | "success" | "failed";
  state_summary: string;
  created_at: string;
  redirect_url?: string;
  instrument: {
    scheme: string;
    last4: string;
  };
  session: {
    session_id: string;
    status: "pending" | "approved";
  };
  last4: string;
  fee_by_network?: string;
  destination?: string;
}

export const APP_NAME_AND_REDIRECT_URL = z.object({
  app_name: z.string(),
  redirect_url: z.string().url(),
});

export const AUTH_SCHEMA = z
  .object({
    token: z.string(),
    user_id: z.string().uuid().optional(),
  })
  .and(zodObjectOrUndefined(APP_NAME_AND_REDIRECT_URL));

export type Auth = z.infer<typeof AUTH_SCHEMA>;

export const LAST_EDIT_SCHEMA = z.union([
  z.literal("input"),
  z.literal("output"),
]);

export type LastEdit = z.infer<typeof LAST_EDIT_SCHEMA>;

export const LAST_EDIT_KEY = "last";
export const AMOUNT_KEY = "amount";
export const ASSET_KEY = "asset_id";
export const CURRENCY_KEY = "currency";
export const LOCK_KEY = "lock";
export const ACTION_KEY = "action"; // only card

export const URL_INPUT_SCHEMA = zodObjectOrUndefined(
  z.object({
    [AMOUNT_KEY]: z.coerce.number(),
    [LAST_EDIT_KEY]: LAST_EDIT_SCHEMA,
  }),
).catch({});

export type UrlInputSchema = z.infer<typeof URL_INPUT_SCHEMA>;

const URL_BASE_PARAMS_SCHEMA = z.object({
  [ASSET_KEY]: z.string().uuid().optional().catch(undefined),
  [CURRENCY_KEY]: z
    .string()
    .transform((v) => v.toUpperCase())
    .optional()
    .catch(undefined),
});

export type UrlBaseParamsSchema = z.infer<typeof URL_BASE_PARAMS_SCHEMA>;

const URL_CONTROL_PARAMS_SCHEMA = z
  .object({
    [ACTION_KEY]: z.literal("card").optional().catch(undefined),
    [LOCK_KEY]: z
      .string()
      .optional()
      .catch(undefined)
      .transform((v) => v !== undefined && v !== null)
      .pipe(z.literal(true).optional().catch(undefined)),
  })
  .catch({});

export type UrlControlParamsSchema = z.infer<typeof URL_CONTROL_PARAMS_SCHEMA>;

export const LAST_SELECT_SCHEMA = URL_BASE_PARAMS_SCHEMA.and(URL_INPUT_SCHEMA);
export type LastSelect = z.infer<typeof LAST_SELECT_SCHEMA>;

export const URL_PARAMS_SHCEMA = LAST_SELECT_SCHEMA.and(
  URL_CONTROL_PARAMS_SCHEMA,
);

export type UrlParamsShcema = z.infer<typeof URL_PARAMS_SHCEMA>;

export const PREORDER_SCHEMA = z
  .object({
    app_id: z.string().uuid(),
    user_id: z.string(),
    order_id: z.string().uuid(),
    timestamp: z.coerce.number(),
    signature: z.string(),
  })
  .and(APP_NAME_AND_REDIRECT_URL)
  .and(URL_PARAMS_SHCEMA);

export type Preorder = z.infer<typeof PREORDER_SCHEMA>;
