import AED_FLAG from "@/assets/flags/aed.png";
import AUD_FLAG from "@/assets/flags/aud.png";
import CAD_FLAG from "@/assets/flags/cad.png";
import CNY_FLAG from "@/assets/flags/cny.png";
import EUR_FLAG from "@/assets/flags/eur.png";
import GBP_FLAG from "@/assets/flags/gbp.png";
import HKD_FLAG from "@/assets/flags/hkd.png";
import IDR_FLAG from "@/assets/flags/idr.png";
import INR_FLAG from "@/assets/flags/inr.png";
import JPY_FLAG from "@/assets/flags/jpy.png";
import KRW_FLAG from "@/assets/flags/krw.png";
import MYR_FLAG from "@/assets/flags/myr.png";
import PHP_FLAG from "@/assets/flags/php.png";
import SGD_FLAG from "@/assets/flags/sgd.png";
import TRY_FLAG from "@/assets/flags/try.png";
import TWD_FLAG from "@/assets/flags/twd.png";
import VND_FLAG from "@/assets/flags/vnd.png";
import USD_FLAG from "@/assets/flags/usd.png";
import NGN_FLAG from "@/assets/flags/ngn.png";
import ZAR_FLAG from "@/assets/flags/zar.png";

export const LANG_KEY = "lang";

export const isProduction = import.meta.env.MODE === "production";
export const isDevelopment = import.meta.env.MODE === "development";

export interface Currency {
  name: string;
  symbol: string;
  flag: string;
}

export const currencies: Record<string, Currency | undefined> = {
  NGN: {
    name: "NGN",
    symbol: "₦",
    flag: NGN_FLAG,
  },
  ZAR: {
    name: "ZAR",
    symbol: "R",
    flag: ZAR_FLAG,
  },
  AED: {
    name: "AED",
    symbol: "AED\u00a0",
    flag: AED_FLAG,
  },
  AUD: {
    name: "AUD",
    symbol: "A$",
    flag: AUD_FLAG,
  },
  CAD: {
    name: "CAD",
    symbol: "C$",
    flag: CAD_FLAG,
  },
  CNY: {
    name: "CNY",
    symbol: "¥",
    flag: CNY_FLAG,
  },
  EUR: {
    name: "EUR",
    symbol: "€",
    flag: EUR_FLAG,
  },
  GBP: {
    name: "GBP",
    symbol: "£",
    flag: GBP_FLAG,
  },
  HKD: {
    name: "HKD",
    symbol: "HK$",
    flag: HKD_FLAG,
  },
  IDR: {
    name: "IDR",
    symbol: "Rp",
    flag: IDR_FLAG,
  },
  INR: {
    name: "INR",
    symbol: "₹",
    flag: INR_FLAG,
  },
  JPY: {
    name: "JPY",
    symbol: "¥",
    flag: JPY_FLAG,
  },
  KRW: {
    name: "KRW",
    symbol: "₩",
    flag: KRW_FLAG,
  },
  MYR: {
    name: "MYR",
    symbol: "RM",
    flag: MYR_FLAG,
  },
  PHP: {
    name: "PHP",
    symbol: "₱",
    flag: PHP_FLAG,
  },
  SGD: {
    name: "SGD",
    symbol: "S$",
    flag: SGD_FLAG,
  },
  TRY: {
    name: "TRY",
    symbol: "₺",
    flag: TRY_FLAG,
  },
  TWD: {
    name: "TWD",
    symbol: "NT$",
    flag: TWD_FLAG,
  },
  VND: {
    name: "VND",
    symbol: "₫",
    flag: VND_FLAG,
  },
  USD: {
    name: "USD",
    symbol: "$",
    flag: USD_FLAG,
  },
};
