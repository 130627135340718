import { useState, useEffect, ReactNode } from "react";
import {
  initLocalStorageDetector,
  localStorageDetector,
  navigatorDetector,
} from "typesafe-i18n/detectors";
import { LANG_KEY } from "../../lib/constant";
import TypesafeI18n from "../../i18n/i18n-react";
import { detectLocale } from "../../i18n/i18n-util";
import { loadLocale } from "../../i18n/i18n-util.sync";
import { Locales } from "../../i18n/i18n-types";

const detectedLocale = detectLocale(
  initLocalStorageDetector(LANG_KEY),
  localStorageDetector,
  navigatorDetector,
);

export const I18nProvider = ({
  locale,
  children,
}: {
  locale?: Locales;
  children?: ReactNode;
}) => {
  const [localesLoaded, setLocalesLoaded] = useState(false);
  const finalLocale = locale || detectedLocale;

  useEffect(() => {
    loadLocale(finalLocale);
    setLocalesLoaded(true);
  }, [finalLocale]);

  if (!localesLoaded) return null;

  return <TypesafeI18n locale={finalLocale}>{children}</TypesafeI18n>;
};
