import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useNavigateBack = () => {
  const navigate = useNavigate();
  return useCallback(() => {
    const canBack = window.history.state && window.history.state.idx > 0;
    if (canBack) return navigate(-1);
    return navigate("/");
  }, [navigate]);
};
