import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import z, {
  UnknownKeysParam,
  ZodObject,
  ZodRawShape,
  ZodTypeAny,
  ZodUndefined,
} from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function zodObjectUndefined<
  Shape extends ZodRawShape,
  UnknownKeys extends UnknownKeysParam = UnknownKeysParam,
  Catchall extends ZodTypeAny = ZodTypeAny,
>(
  schema: ZodObject<Shape, UnknownKeys, Catchall>,
): ZodObject<{ [k in keyof Shape]: ZodUndefined }, UnknownKeys, Catchall>;
export function zodObjectUndefined<
  Shape extends ZodRawShape,
  Mask extends { [k in keyof Shape]?: true },
  UnknownKeys extends UnknownKeysParam = UnknownKeysParam,
  Catchall extends ZodTypeAny = ZodTypeAny,
>(
  schema: ZodObject<Shape, UnknownKeys, Catchall>,
  mask?: Mask,
): ZodObject<
  z.objectUtil.noNever<{
    [k in keyof Shape]: k extends keyof Mask ? ZodUndefined : Shape[k];
  }>,
  UnknownKeys,
  Catchall
>;
export function zodObjectUndefined<
  Shape extends ZodRawShape,
  Mask extends { [k in keyof Shape]?: true },
>(schema: ZodObject<Shape>, mask?: Mask) {
  const newShape: Record<string, ZodTypeAny> = {};
  z.util.objectKeys(schema.shape).forEach((key) => {
    const fieldSchema = schema.shape[key];

    if (mask && !mask[key]) {
      newShape[key] = fieldSchema;
    } else {
      newShape[key] = z.undefined();
    }
  });

  return new ZodObject({ ...schema._def, shape: () => newShape });
}

export const zodObjectOrUndefined = <
  Shape extends ZodRawShape,
  UnknownKeys extends UnknownKeysParam = UnknownKeysParam,
  Catchall extends ZodTypeAny = ZodTypeAny,
>(
  schema: ZodObject<Shape, UnknownKeys, Catchall>,
) => schema.or(zodObjectUndefined(schema));

type Entries<T> = (keyof T extends infer U
  ? U extends keyof T
    ? [U, T[U]]
    : never
  : never)[];

interface FOo {
  a: string;
  b: number;
  c: boolean;
}

export type Result = Entries<FOo>;

export const zodObjectMap = <
  Shape extends ZodRawShape,
  T extends ZodTypeAny,
  UnknownKeys extends UnknownKeysParam = UnknownKeysParam,
  Catchall extends ZodTypeAny = ZodTypeAny,
>(
  obj: ZodObject<Shape, UnknownKeys, Catchall>,
  mapper: (key: keyof Shape, value: Shape[keyof Shape]) => T,
): ZodObject<{ [k in keyof Shape]: T }, UnknownKeys, Catchall> => {
  const entries = Object.entries(obj.shape) as Entries<Shape>;

  const newShape = Object.fromEntries(
    entries.map(([key, value]) => [key, mapper(key, value)]),
  ) as Record<keyof Shape, T>;

  return new ZodObject({
    ...obj._def,
    shape: () => newShape,
  });
};
