import type { BaseTranslation } from "../i18n-types.js";

const en = {
  pay: "Pay",
  received: "Received",
  home: {
    payLimit: "Min {minimum} {currency}, Max {maximum} {currency}",
    previewOrder: "Preview Order",
    selectAToken: "Select a token",
    selectCurrency: "Select a currency",
    selectCard: "Select a card",
    addDebitOrCreditCard: "Add Debit or Credit Card",
    enterAddress: "Enter wallet address",
    address: "Wallet address",
    inputCard: {
      cardNumber: "Card Number",
      expiryDate: "Expiry Date",
      securityCode: "Security Code",
      holder: "Cardholder Name",
      action: "Add card",
    },
    buy: "Buy {symbol:string}",
    sell: "Sell {symbol:string}",
  },
  bankTransferInstruction: {
    title: "Bank Transfer Instruction",
    menuName: "Bank Transfer",
    desc: [
      "Please confirm that the name on your bank account matches the KYC information.",
      "Please copy the above information to your bank transfer slip.",
    ],
    sections: {
      beneficiary: {
        title: "Beneficiary",
        items: {
          remarks: {
            title: "Remarks",
            desc: "This code is used to identify your transfer, please be sure to add it.",
          },
          IBAN: "IBAN",
          accountName: "Account Name",
          accountNumber: "Account Number",
          address: "Address",
        },
      },
      beneficiaryBank: {
        title: "Beneficiary Bank",
        items: {
          BIC_SWIFTCode: "BIC / SWIFT Code",
          bankName: "Bank Name",
          bankAddress: "Bank Address",
          bankCity: "Bank City",
          bankCountry: "Bank Country",
        },
      },
    },
  },
  payment: {
    method: "Payment Method",
    totalToken: "Total Token",
    price: "Price",
    feeByGateway: "Fee by Gateway",
    feeByMixin: "Fee by Mixin",
    feeByNetwork: "Fee by Network",
    destination: "Destination",
    totalAmount: "Total Amount",
    tips: "You may be charged additional fees by your bank, please contact your bank for details.",
  },
  login: {
    action: "Login with Mixin",
    actionWithPreorder: "Authorization",
    actionWithEmail: "Continue with Email",
    email: {
      title: "Login to Mixin Route",
      actionWithSend: "Continue with Email",
      verificate: "Verificate code",
      otherLoginOptions: "← Other Login Options",

      emailPlaceholder: "Email address",
      securityCodePlaceholder: "Security Code",

      verificateCodeTips:
        "Please enter the verification code sent to {email:string}",
    },
  },
  order: {
    detail: "Order Detail",
    payment: {
      fetching: "Fetching order",
      failed: "Payment failed",
      paying: "Paying",
      success: "Payment completed",
      successDesc:
        "After the payment is completed, the token will be transferred directly to your wallet.",
      expired: "Price expired",
    },
    status: {
      created: "Created",
      paying: "Pending",
      success: "Success",
      failed: "Failed",
      returnTo: "Return to {name:string}",
    },
    useLatestPrice: "Use latest price",
  },
  orders: {
    title: "Orders",
    boughtSome: "Bought {symbol:string}",
  },
  card: {
    visa: "Visa",
    mastercard: "MasterCard",
  },
  kyc: "KYC",
  status: "Status",
  name: "Mixin Route",
  slogan: "Instantly buy cryptocurrency to your wallet",
  delete: "Delete",
  done: "Done",
  cancel: "Cancel",
  retry: "Retry",
  back: "Back",
  copied: "Copied",
  error: {
    10601: "The card has expired.",
    10602: "The price quote has expired.",
    10604: "Unsupported card, please try another card.",
    10605: "The card is being used at the same time.",
    10606: "Too many attempts, please try again later.",
    10607: "Too many cards added.",
    10608: "Cardholder name not match your name.",
    10609: "Session expired.",
    10610: "Inconsistency country failed, please contact customer service.",
    20111: "Send email rate limit, please try again later.",
    20113: "Invalid verification code.",
    20114: "Verification code expired.",
    20115: "Invalid address.",
    // Insufficient amount
    20116: "The price quote has expired.",
    addCard: "Failed to add card.",
    quote: "Failed to get the latest quote, maybe the price has expired.",
    auth: "Failed to authenticate, detail: {error:string}",
    kycBlocked:
      "Failed to verify your identity, please contact customer service.",

    pay: "Failed to pay, please try again.",

    unknown: "Unknown error, please try again later.",
    network: "Network error, please try again later.",

    notFound: "Not Found",
    timeout: "Network timeout, please try again later.",
    params: "Invalid parameters",

    copy: "Failed to copy to clipboard",
  },
  form: {
    required: "Required.",
    email: "Invalid email address.",
  },
} satisfies BaseTranslation;

export default en;
