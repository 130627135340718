import { Auth, AUTH_SCHEMA, LAST_SELECT_SCHEMA, LastSelect } from "./types";

const OLD_TOKEN_KEY = "AUTH_TOKEN";
const OLD_PHONE_KEY = "PHONE";

const AUTH_KEY = "AUTH";
const LAST_SELECT_KEY = "SELECT";

export const getAuth = (): Auth | undefined => {
  try {
    return AUTH_SCHEMA.parse(
      JSON.parse(window.localStorage.getItem(AUTH_KEY) ?? ""),
    );
  } catch (_) {
    // ignore
  }

  const token = window.localStorage.getItem(OLD_TOKEN_KEY);
  const phone = window.localStorage.getItem(OLD_PHONE_KEY);

  if (!token && !phone) return;

  window.localStorage.setItem(
    AUTH_KEY,
    JSON.stringify({
      token,
      phone,
    }),
  );

  window.localStorage.removeItem(OLD_TOKEN_KEY);
  window.localStorage.removeItem(OLD_PHONE_KEY);

  return getAuth();
};
export const setAuth = (auth: Auth) =>
  window.localStorage.setItem(AUTH_KEY, JSON.stringify(auth));

export const removeAuth = () => window.localStorage.removeItem(AUTH_KEY);

export const getLastSelect = () => {
  const json = window.localStorage.getItem(LAST_SELECT_KEY);
  if (!json) return;
  try {
    return LAST_SELECT_SCHEMA.parse(JSON.parse(json));
  } catch (error) {
    return undefined;
  }
};

export const setLastSelect = (select: LastSelect) =>
  window.localStorage.setItem(LAST_SELECT_KEY, JSON.stringify(select));
