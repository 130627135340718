import { useCallback } from "react";
import { useI18nContext } from "../../i18n/i18n-react";
import { ApiError } from "../service";
import { TimeoutError } from "ky";

export const useHandleErrorMessage = () => {
  const { LL } = useI18nContext();
  return useCallback(
    (e: unknown) => {
      if (e && e instanceof ApiError) {
        if (e.code === 404) return LL.error.notFound();
        const errors = LL.error as unknown as Record<
          string | number,
          () => string
        >;

        return (
          (errors[e.code + ""] || errors[e.code])?.() || LL.error.unknown()
        );
      }
      if (e && e instanceof TimeoutError) return LL.error.timeout();
      return LL.error.network();
    },
    [LL.error],
  );
};
