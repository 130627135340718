import { Outlet } from "react-router-dom";
import { I18nProvider } from "./I18nProvider";
import { QueryProvider } from "./QueryProvider";
import { Toaster } from "../ui/sonner";

export const Providers = () => (
  <I18nProvider>
    <QueryProvider>
      <Outlet />
      <Toaster position="top-center" />
    </QueryProvider>
  </I18nProvider>
);
