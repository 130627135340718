import { Link, Outlet } from "react-router-dom";
import { Suspense } from "react";
import { Loader2 } from "lucide-react";
import { cn } from "../../lib/utils";
// import desktopBg from "@/assets/desktop-bg.svg?url";
import MixinLogo from "@/assets/mixin-logo.svg?react";

export const Layout = () => (
  <>
    <div
      className="flex h-full flex-col items-center justify-between bg-center bg-repeat"
      style={
        {
          // background: `url(${desktopBg})`,
        }
      }
    >
      <div className="hidden w-full bg-white py-3 md:block">
        <div className="mx-a px-1/10 w-full">
          <Link
            className="flex-center flex h-12 w-fit space-x-3"
            to="https://mixin.one/"
            target="_blank"
          >
            <MixinLogo />
            <div>Mixin</div>
            <div className="h-4 w-0.5 rounded-sm bg-[#D9D9D9]"></div>
            <Link to="/">Route</Link>
          </Link>
        </div>
      </div>
      <div
        className={cn(
          "mx-a h-full w-full bg-white",
          "md:shadow-[0px_8px_34px_0px_rgba(0,0,0,0.04)]",
          "md:(w-100 rounded-5 h-155 overflow-hidden)",
        )}
      >
        <Suspense
          fallback={
            <main className="flex-center">
              <Loader2 className="animate-spin" />
            </main>
          }
        >
          <Outlet />
        </Suspense>
      </div>
      <div></div>
    </div>
  </>
);
