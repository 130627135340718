import "@unocss/reset/tailwind-compat.css";
import "virtual:uno.css";
import "./global.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { isProduction } from "./lib/constant.ts";
import { sentryInit } from "./sentry.ts";

sentryInit();

(async () => {
  if (isProduction) return;
  const VConsole = (await import("vconsole")).default;
  new VConsole();
})();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
