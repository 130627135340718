import z from "zod";

export const ENV_SCHEMA = z.object({
  VITE_CLIENT_ID: z.string().uuid(),
  VITE_CKO_PK: z.string(),
  VITE_ROUTE_SERVER: z
    .string()
    .url()
    .transform((v) => new URL(v).origin),
  VITE_SENTRY_DSN: z.string().url(),
  VITE_SENTRY_ORG: z.string(),
  VITE_SENTRY_PROJECT: z.string(),
});

export const env = ENV_SCHEMA.parse(import.meta.env);
